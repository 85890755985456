import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/home/Home";
import SMESalesForge from "./pages/SMESalesForge/SMESalesForge";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/sme-salesforge" element={<SMESalesForge />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
