import React from "react";
import { Col, Container, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import logo2 from "../assets/images/milescorplogo2.png";
// import number1 from "../assets/images/number1.png";
// import premium from "../assets/images/premium-quality.png";
import img from "../assets/images/milescorplogo.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer">
      <Container>
        <Row className="top_part">
          <Col xs={12} md={4} xl={4}>
            <img src={img} alt="" />
            <h2>MilesCorp</h2>
            <p>Going The Extra Mile</p>
          </Col>
          <Col xs={6} md={4} xl={4}>
            <h3>Reach Us</h3>
            <p>
              <a href="mailto:mileslemi@gmail.com">mileslemi@gmail.com</a>
            </p>
            <p>
              <a href="tel:+254706803305">0706803305</a>
            </p>
          </Col>
          <Col xs={6} md={4} xl={4} className="quick_links">
            {/* <img src={number1} alt="" />
            <img src={premium} alt="" /> */}
            <div>
              <h5>Quick Links</h5>
              <ul>
                <li>
                  <a href="/">Company</a>
                </li>
                <li>
                  <a href="/#services">Services</a>
                </li>
                <li>
                  <a href="/#ourwork">Our Work</a>
                </li>
                <li>
                  <a href="/#contact">Contact</a>
                </li>
                <li>
                  <a href="/sme-salesforge">SME SalesForge</a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="bottom_part">
          <Col xs={12} md={12} xl={12}>
            <p>&copy; {year} MilesCorp. All Rights Reserved. </p>
          </Col>
          {/* <Col xs={12} md={6} xl={6} style={{ textAlign: "start" }}>
            <ul className="row">
              <li className="col-md-3">
                <a href="/">Company</a>
              </li>
              <li className="col-md-3">
                <a href="/#services">Services</a>
              </li>
              <li className="col-md-3">
                <a href="/#ourwork">Our Work</a>
              </li>
              <li className="col-md-3">
                <a href="/#contact">Contact</a>
              </li>
            </ul>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
