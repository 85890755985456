import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../../assets/images/development-desktop.png";
import "animate.css";

const Banner = () => {
  return (
    <section className="banner" id="company">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={5} xl={6}>
            <div>
              <h1>Going the Extra Mile</h1>
              <p>
                We help businesses deliver digital customer experiences that are
                personalized, optimized, and synchronized.
              </p>
              <button className="vvd">
                <a href="#services">
                  Let's Start <ArrowRightCircle size={24} />
                </a>
              </button>
            </div>
          </Col>
          <Col xs={12} md={7} xl={6}>
            <img src={headerImg} alt="" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
