import React from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import file from "../../assets/files/smesalesforge2.2.exe";

const SMESalesForge = () => {
  return (
    <div className="sme-salesforge">
      <section className="banner">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={5} xl={6}>
              <div>
                <h1>SME SalesForge</h1>
                <p>
                  Streamline your business with our all-in-one desktop app for
                  accounting, POS, and inventory management.
                </p>
              </div>
            </Col>
            <Col xs={12} md={7} xl={6}></Col>
          </Row>
        </Container>
      </section>
      <div className="container sme-content">
        <h2> What is SME-SalesForge?</h2>
        <p>
          SME SalesForge is a system developed by MilesCorp, designed to
          simplify and enhance key business operations for small and medium
          enterprises (SMEs). It is an offline-based solution that offers
          comprehensive tools for sales, inventory, and employee management,
          while delivering actionable insights to support better business
          decision-making. Whether you're looking to streamline operations,
          improve decision-making, or gain deeper insights into your business
          performance, SME SalesForge empowers you with the tools you need to
          succeed.
        </p>
        <h2>Why choose SME-SalesForge?</h2>
        <h5>Key Features and Capabilities</h5>
        <ListGroup as="ol" numbered>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">
                Sales and Point of Sale (POS) Management:
              </div>
              SME SalesForge streamlines the sales process with an intuitive POS
              system that records transactions accurately and efficiently.
              Whether managing day-to-day sales or tracking revenue over time,
              the system ensures businesses have the data they need at their
              fingertips.
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Inventory Management:</div>
              Effective inventory management is critical for profitability, and
              SME SalesForge delivers with precision. The system tracks stock
              levels, alerts businesses when products run low, and provides
              detailed reports to prevent overstocking or stockouts.
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Employee Management:</div>
              Manage your team effectively with tools that assign roles, monitor
              performance, and ensure accountability. SME SalesForge includes
              features for setting user privileges, ensuring every team member
              has access only to the tools and information they need.
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">
                Business Analytics with Graphical Insights:
              </div>
              Visualize your business performance with detailed graphs that
              display: Daily, monthly, and yearly sales trends. Best-selling
              products and underperforming items. Critical metrics for growth
              analysis and decision-making. These insights empower business
              owners to identify patterns, adjust strategies, and optimize
              operations for greater profitability.
            </div>
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Customizable User Privileges:</div>
              Different users can be assigned varying levels of access and
              control, ensuring secure and efficient workflow management. This
              feature protects sensitive business data and enables smoother
              operations across the team.
            </div>
          </ListGroup.Item>
        </ListGroup>
        <br />
        <h5>Benefits you get with us.</h5>
        <br />
        <ListGroup as="ul">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">
                Offline Functionality and Data Security
              </div>
              Unlike many cloud-based systems, SME SalesForge operates entirely
              offline, ensuring uninterrupted access to your data even in areas
              with poor or no internet connectivity. All data is stored locally,
              giving businesses complete control and minimizing risks associated
              with online threats.
            </div>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Backup and Recovery Solutions</div>
              To safeguard your business data, SME SalesForge offers robust
              backup solutions. Regular backups ensure that critical data can be
              restored quickly in the event of system failure or accidental data
              loss, keeping your business running smoothly.
            </div>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Customization to Suit Your Needs</div>
              Recognizing that every SME is unique, SME SalesForge allows
              customization based on client feedback. Businesses can adapt the
              system to fit their specific needs, enhancing usability and
              relevance to their operations.
            </div>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Ease of Use and Installation</div>
              Designed for simplicity, SME SalesForge is easy to install and
              use, requiring minimal storage space. Its user-friendly interface
              ensures a smooth onboarding experience, even for those with
              limited technical expertise.
            </div>
          </ListGroup.Item>

          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">24/7 Support</div>
              MilesCorp provides round-the-clock support to ensure that your
              business operations are never disrupted. From troubleshooting to
              system updates, help is always just a call away.
            </div>
          </ListGroup.Item>
        </ListGroup>
        <h2>How to Get It.</h2>
        <ol>
          <li>
            Click{" "}
            <u>
              <a href={file} download>
                here
              </a>
            </u>{" "}
            to download.
          </li>
          <li>Sign Up</li>
          <li>
            Contact this number [<a href="tel:+254706803305">0706803305</a>] or
            email at [
            <a href="mailto:mileslemi@gmail.com">mileslemi@gmail.com</a>] to be
            activated.
          </li>
        </ol>
        <h2>FAQs</h2>
        <p>...............</p>
        <h2>Coming Features</h2>
        <ul>
          <li> AI Integration</li>
          <li> Online Marketing</li>
        </ul>
      </div>
    </div>
  );
};

export default SMESalesForge;
