import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import navIcon1 from "../assets/icons/nav-icon1.svg";
import navIcon2 from "../assets/icons/github-svg.svg";
import logo from "../assets/images/milescorplogo.png";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    //   add event listener on mounting
    window.addEventListener("scroll", onScroll);

    //   remove it when component get removed from the dom
    return () => window.removeEventListener("scroll", onscroll);
  }, []);
  return (
    <Navbar
      expand="lg"
      className={scrolled ? "scrolled navbar-dark" : "navbar-dark"}
    >
      <Container>
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link
              to="/"
              className={
                activeLink === "company"
                  ? "active navbar-link nav-link"
                  : "navbar-link nav-link"
              }
              onClick={() => setActiveLink("company")}
            >
              Company
            </Link>
            <Nav.Link
              href="/#services"
              className={
                activeLink === "services" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => setActiveLink("services")}
            >
              Services
            </Nav.Link>
            <Nav.Link
              href="/#ourwork"
              className={
                activeLink === "ourwork" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => setActiveLink("ourwork")}
            >
              Our Work
            </Nav.Link>
            <Link to="/sme-salesforge" className="navbar-link nav-link">
              SME-SalesForge
            </Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/mileslemi/">
                <img src={navIcon1} alt="" />
              </a>
              <a href="https://github.com/Mileslemi">
                <img src={navIcon2} alt="" />
              </a>
            </div>
            <button className="vvd">
              {/* <span>Contact Us</span>
               */}
              <Nav.Link href="/#contact">Contact Us</Nav.Link>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
